import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout/layout";
import Thanks from "../components/Thanks/thanks";
import config from "../../data/SiteConfig";
import Card from "react-md/lib/Cards/Card";

class ThanksPage extends Component {
  render() {
    return (
        <Layout location={this.props.location} title="Thanks">
            <div className="thanks-container">
            <Helmet>
                <title>{`Thanks | ${config.siteTitle}`}</title>
                <link rel="canonical" href={`${config.siteUrl}/thanks/`} />
            </Helmet>
            <Thanks />
            </div>
        </Layout>
    );
  }
}

export default ThanksPage;
